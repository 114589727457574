$(document).ready(function () {
  var matrixCtrl = new ScrollMagic.Controller();

  if ($(".matrix__graphics #graph-1").length) {
    $(".matrix__graphics #graph-1").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          rotation: 28,
          y: 0,
          transformOrigin: "center",
        },
        {
          rotation: 5,
          y: 15,
        }
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(matrixCtrl);
    });
  }

  if ($(".matrix__graphics #graph-1").length) {
    $(".matrix__graphics #graph-2").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          rotation: 32,
          x: 0,
          y: 0,
          transformOrigin: "center",
        },
        {
          rotation: 15,
          x: -26,
          y: 37,
        }
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(matrixCtrl);
    });
  }
  if ($(".matrix__graphics #graph-3").length) {
    $(".matrix__graphics #graph-3").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          transformOrigin: "center",
        },
        {}
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(matrixCtrl);
    });
  }

  if ($(".matrix__graphics #graph-4").length) {
    $(".matrix__graphics #graph-4").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          y: 0,
          rotation: 26,
          transformOrigin: "center",
        },
        {
          y: 70,
          rotation: 50,
        }
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(matrixCtrl);
    });
  }

  if ($(".matrix__animation-trigger").length) {
    $(".matrix__animation-trigger").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        ".matrix__animation-trigger img:nth-of-type(1)",
        {
          opacity: 0,
          yPercent: 100,
          ease: "power1.in",
        },
        {
          duration: 1.2,
          opacity: 1,
          yPercent: -40,
        },
        "<.6"
      )
        .fromTo(
          ".matrix__animation-trigger img:nth-of-type(2)",
          {
            opacity: 0,
            yPercent: 100,
            ease: "power1.in",
          },
          {
            duration: 1.2,
            opacity: 1,
            yPercent: -50,
          },
          "<.6"
        )
        .fromTo(
          ".matrix__animation-trigger img:nth-of-type(3)",
          {
            opacity: 0,
            yPercent: 100,
            ease: "power1.in",
          },
          {
            duration: 1.2,
            opacity: 1,
            yPercent: -40,
          },
          "<.6"
        );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1,
        reverse: true,
      })
        .setTween(tl)
        .addTo(matrixCtrl);
    });
  }
});
