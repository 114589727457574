/* --------------------
    Variables
-------------------- */



/* --------------------
    Event Handler
-------------------- */
$(document).on("click", '.playbutton', playvideo)
$(document).on("click", 'video', stopvideo)



/* --------------------
    Functions
-------------------- */
function playvideo() {
    if ($(this).parent().find('video')[0].paused) {
        $(this).parent().find('video')[0].play()
        $('video').on("ended", showControlls)
        $(this).parent().find('.playbutton .icon').attr('src', "/ressources/media/icons/Pause-Button-2x.png")
        $(this).parent().find('.playbutton .text').attr('src', "/ressources/media/icons/Pause-Button-Text.png")
        $(this).parent().find('.video__heading').addClass('--hidden')
    } else {
        $(this).parent().find('video')[0].pause();
        $(this).parent().find('.playbutton .icon').attr('src', "/ressources/media/icons/Play-Button-2x.png")
        $(this).parent().find('.playbutton .text').attr('src', "/ressources/media/icons/Play-Button-Text.png")
        $(this).parent().find('.video__heading').removeClass('--hidden')
    }
}

function stopvideo() {
    if (!$(this).parent().find('video')[0].paused) {
        $(this).parent().find('video')[0].pause()
        $(this).parent().find('.playbutton .icon').attr('src', "/ressources/media/icons/Play-Button-2x.png")
        $(this).parent().find('.playbutton .text').attr('src', "/ressources/media/icons/Play-Button-Text.png")
        $(this).parent().find('.video__heading').removeClass('--hidden')
    }
}

function showControlls() {
    $(this).parent().find('.playbutton .icon').attr('src', "/ressources/media/icons/Play-Button-2x.png")
    $(this).parent().find('.playbutton .text').attr('src', "/ressources/media/icons/Play-Button-Text.png")
    $(this).parent().find('.video__heading').removeClass('--hidden')
}
