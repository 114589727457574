/* --------------------
    Event Handler
-------------------- */
$(document).on("change", "#file", fileChange);

const emptyText = $("#uploadinfo").text();

const filledText_singular = $("#filetext_singular").text();
const filledText_plural = $("#filetext_plural").text();

/* --------------------
    Functions
-------------------- */
function fileChange(event) {
  const fileCount = event.target.files.length;
  const label = $("#uploadinfo");
  if (fileCount == 1) {
    label.text(filledText_singular);
  } else if (fileCount > 1) {
    label.text(filledText_plural.replace("[x]", fileCount));
  } else {
    label.text(emptyText);
  }
}
