/* --------------------
    Event Handler
-------------------- */
$(document).on("click", ".hamburger", openMenu);
$(document).on("click", ".has-subnavi>a", openSubmenu);


/* --------------------
    Functions
-------------------- */
function openMenu() {
    if ($(".hamburger").hasClass("is-active")) {
        $(".hamburger").removeClass("is-active");
        $("body").removeClass("no-body-scrolling");
        $("nav").removeClass("is-active");
    } else {
        $(".hamburger").addClass("is-active");
        $("body").addClass("no-body-scrolling");
        $("nav").addClass("is-active");
    }
}

function openSubmenu(e) {
    var windowWidth = $(window).width();
    if (windowWidth < 1260) {
        e.preventDefault();
        if ($(this).hasClass('open-submenu')) {
            $(this).removeClass('open-submenu')
        } else {
            $(this).addClass('open-submenu')
        }
    }
}