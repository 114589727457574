/* --------------------
    Event Handler
-------------------- */
$(document).on("click", '.lightbox-func-close', closeLightbox)
$(document).on("click", '.lightbox-func-open', openLightbox)



/* --------------------
    Functions
-------------------- */
function closeLightbox() {
    $(this).parent().fadeOut();
    $("body").removeClass("no-body-scrolling");
    $("#overlay").fadeOut();
}

function openLightbox() {
    $(this).parent().find(".lightbox").fadeIn();
    $("body").addClass("no-body-scrolling");
    $("#overlay").fadeIn();
}