$(document).ready(function () {
  if ($(".jobslider__slider").length) {
    var jobsliderCtrl = new ScrollMagic.Controller();

    /* ------------------------------
        BASIC FADE-ANIMATIONS
        -----

        Elements fly in from
        left / right / bottom
    ------------------------------ */

    let tl = gsap.timeline();

    tl.fromTo(
      ".jobslider__item",
      {
        opacity: 0,
        x: "-150",
      },
      {
        opacity: 1,
        x: "0",
        ease: "power4",
        stagger: {
          each: 0.15,
          from: "end",
        },
      }
    );

    new ScrollMagic.Scene({
      triggerElement: ".jobslider__slider",
      triggerHook: 1,
      reverse: true,
    })
      .setTween(tl)
      .addTo(jobsliderCtrl);

    new ScrollMagic.Scene({
      triggerElement: ".jobslider__slider",
      triggerHook: 0.85,
      duration: "50%",
      reverse: false,
    })
      .on("enter", function () {
        document.getElementsByClassName("jobslider__arrows--right")[0].click();
      })
      .on("end", function () {
        document.getElementsByClassName("jobslider__arrows--left")[0].click();
      })
      .addTo(jobsliderCtrl);
  }
});
