var prevScrollTop = 0;

$(window).scroll(function (event) {
  var scrollTop = $(this).scrollTop();
  if (scrollTop < 96) {
    scrollTop = 0;
  }
  if (scrollTop > $("body").height() - $(window).height()) {
    scrollTop = $("body").height() - $(window).height();
  }

  if (scrollTop >= prevScrollTop && scrollTop) {
    if ($(this).scrollTop() > window.innerHeight) {
      // scrolling down
      $(".navigation").addClass("navigation--off");
    }
  } else {
    // scrolling up
    $(".navigation").removeClass("navigation--off");
  }

  prevScrollTop = scrollTop;
});
