/* --------------------
    Event Handler
-------------------- */
$(document).on("afterChange", ".jobslider__slider", setScrollIndicatorPosition);

$(document).ready(function () {
  if ($(".jobslider__slider").length) {
    initJobslider();
  }

  if ($(".profile__readmore--collapsed").length) {
    initReadMore();
  }
});

/* --------------------
    Functions
-------------------- */
function setScrollIndicatorPosition(event, slick, currentSlide) {
  if (currentSlide == slick.slideCount - 4) {
    slick.$nextArrow.css("opacity", 0);
    slick.$nextArrow.css("pointer-events", "none");
  } else if (currentSlide == 0) {
    slick.$prevArrow.css("opacity", 0);
    slick.$prevArrow.css("pointer-events", "none");
  } else {
    slick.$nextArrow.css("opacity", 1);
    slick.$nextArrow.css("pointer-events", "all");
    slick.$prevArrow.css("opacity", 1);
    slick.$prevArrow.css("pointer-events", "all");
  }

  let slidesToShow;
  if (window.innerWidth < 420) {
    slidesToShow = 1;
  } else {
    slidesToShow = 2;
  }

  let position = (80 / (slick.slideCount - slidesToShow)) * slick.currentSlide;
  $(".jobslider__scrollindicator").attr("style", "left: " + position + "%");
}

function initJobslider() {
  $(".jobslider__slider").slick({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: ".jobslider__arrows--right",
    prevArrow: ".jobslider__arrows--left",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
}

function initReadMore() {
  $(".profile__readmore--collapsed").click(function () {
    $(this).toggleClass("expanded");
  });
}
