$(document).ready(function () {

    var generalCtrl = new ScrollMagic.Controller();


    /* ------------------------------
        BASIC FADE-ANIMATIONS
        -----

        Elements fly in from
        left / right / bottom
    ------------------------------ */

    $(".fadein").each(function (i) {
        var fadeElement = $(this)
        var tl = gsap.timeline();

        tl.fromTo(fadeElement, {
            opacity: 0,
            y: 20
        }, {
            opacity: 1,
            y: 0
        })

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.5,
                reverse: true
            })
            .setTween(tl.duration(.4))
            .addTo(generalCtrl);
    });


    $(".fadein-left").each(function (i) {
        let fadeElement = $(this)
        let tl = gsap.timeline()

        tl.fromTo(fadeElement, {
            opacity: 0,
            x: -200,
            ease: 'power4.in'
        }, {
            opacity: 1,
            x: 0
        })

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 1,
                reverse: true
            })
            .setTween(tl.duration(1.2))
            .addTo(generalCtrl);
    })
    
    $(".fadein-right").each(function (i) {
        let fadeElement = $(this)
        let tl = gsap.timeline()

        tl.fromTo(fadeElement, {
            opacity: 0,
            x: 200,
            ease: 'power4.in'
        }, {
            opacity: 1,
            x: 0
        })

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 1,
                reverse: true
            })
            .setTween(tl.duration(1.2))
            .addTo(generalCtrl);
    })
    
    $(".fadein-bottom").each(function (i) {
        let fadeElement = $(this)
        let tl = gsap.timeline()

        tl.fromTo(fadeElement, {
            opacity: 0,
            y: 200,
            ease: 'power4.in'
        }, {
            opacity: 1,
            y: 0
        })

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: .9,
                reverse: true
            })
            .setTween(tl.duration(.8))
            .addTo(generalCtrl);
    })
    
    $(".fadein-bottom-late").each(function (i) {
        let fadeElement = $(this)
        let tl = gsap.timeline()

        tl.fromTo(fadeElement, {
            opacity: 0,
            y: 200
        }, {
            opacity: 1,
            y: 0,
            ease: 'power1.in'
        })

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 1,
                reverse: true
            })
            .setTween(tl.duration(.5))
            .addTo(generalCtrl);
    })



    /* ------------------------------
        BASIC PARALLAX-ANIMATION
        -----

        Shifts the Element up
        while scrolling.
    ------------------------------ */

    $(".prllx").each(function (i) {
        let fadeElement = $(this)
        let tl = gsap.timeline()

        tl.fromTo(fadeElement, {
            y: 55
        }, {
            y: -55
        })

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: .9,
                duration: "100%",
                reverse: true
            })
            .setTween(tl)
            .addTo(generalCtrl);
    })
    
    $(".prllx-s").each(function (i) {
        let fadeElement = $(this)
        let tl = gsap.timeline()

        tl.fromTo(fadeElement, {
            y: 25
        }, {
            y: -25
        })

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: .9,
                duration: "100%",
                reverse: true,
                ease: 'power4'
            })
            .setTween(tl)
            .addTo(generalCtrl);
    })
    let prllxHzScene
    $(".prllx-hz").each(function (i) {
        let fadeElement = $(this)
        let tl = gsap.timeline()

        tl.fromTo(fadeElement, {
            x: 45
        }, {
            x: -45
        })

        prllxHzScene= new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: .9,
                duration: "100%",
                reverse: true,
                ease: ''
            })
            .setTween(tl)
            .addTo(generalCtrl);
            
    })




});


