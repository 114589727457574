$(document).ready(function () {
  var independentCtrl = new ScrollMagic.Controller();

  if ($(".independent-minds__graphics #graph-1").length) {
    $(".independent-minds__graphics #graph-1").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          rotation: 22,
          y: 0,
          x: 0,
          transformOrigin: "center",
        },
        {
          rotation: 45,
          y: 25,
          x: 15,
        }
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(independentCtrl);
    });
  }

  if ($(".independent-minds__graphics #graph-2").length) {
    $(".independent-minds__graphics #graph-2").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          rotation: 0,
          y: 0,
          transformOrigin: "center",
        },
        {
          rotation: -24,
          y: 35,
        }
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(independentCtrl);
    });
  }

  if ($(".independent-minds__graphics #graph-3").length) {
    $(".independent-minds__graphics #graph-3").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          rotation: -20,
          x: 0,
          y: 0,
          transformOrigin: "center",
        },
        {
          rotation: 10,
          x: -15,
          y: 58,
        }
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(independentCtrl);
    });
  }

  if ($(".independent-minds__graphics #graph-4").length) {
    $(".independent-minds__graphics #graph-4").each(function (i) {
      let fadeElement = $(this);
      let tl = gsap.timeline();

      tl.fromTo(
        fadeElement,
        {
          rotation: 20,
          x: 0,
          y: 0,
          transformOrigin: "center",
        },
        {
          rotation: 5,
          x: 25,
          y: -36,
        }
      );

      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.8,
        duration: "100%",
        reverse: true,
      })
        .setTween(tl)
        .addTo(independentCtrl);
    });
  }
});
