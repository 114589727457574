$(document).ready(function () {
  var textCtrl = new ScrollMagic.Controller();

  //start looop
  if ($(".moving-text-section").length) {
    $(".moving-text-section").each(function (i) {
      var triggerElement = $(this).find(".trigger--moving-text");
      var movingTextOne = $(this).find(".moving-text--1");
      var movingTextTwo = $(this).find(".moving-text--2");

      new ScrollMagic.Scene({
        triggerElement: triggerElement[0],
        triggerHook: 1,
        duration: $(window).height() + 200,
      })
        .setTween(animateText())
        .addTo(textCtrl);

      function animateText() {
        return gsap
          .timeline()
          .fromTo(
            movingTextOne,
            {
              x: "-4%",
            },
            {
              x: "4%",
              ease: "linear",
            }
          )
          .fromTo(
            movingTextTwo,
            {
              x: "4%",
            },
            {
              x: "-4%",
              ease: "linear",
            },
            "<"
          );
      }
    });
  }
});

//Alternative version with sonstantily moving text

function animateText2() {
  return gsap
    .timeline({ repeat: -1 })
    .fromTo(
      "#animated-text--1",
      {
        x: "-12%",
      },
      {
        x: "12%",
        ease: "linear",
        duration: 20,
      }
    )
    .fromTo(
      "#animated-text--2",
      {
        x: "2%",
      },
      {
        x: "-20%",
        ease: "linear",
        duration: 20,
      },
      "<"
    )
    .fromTo(
      "#animated-text--1",
      {
        x: "12%",
      },
      {
        x: "-12%",
        ease: "linear",
        duration: 20,
      }
    )
    .fromTo(
      "#animated-text--2",
      {
        x: "-20%",
      },
      {
        x: "2%",
        ease: "linear",
        duration: 20,
      },
      "<"
    );
}

if ($("#animated-text--1").length && $("#animated-text--2").length) {
  animateText2();
}
