function renderInstagram() {
    let feedItems = [...$(".feed-item")];
    let processedItems = [];

    //get data from hidden juicer
    feedItems.forEach(feedItem => {
        let processedItem = {}
        processedItem.image = $(feedItem).find(".j-image").data("image");
        processedItem.url = $(feedItem).find(".j-image").attr("href");
        if (processedItem.url.includes("instagram.com")) {
            processedItems.push(processedItem);
        };
    });

    //render feed
    processedItems.forEach(item => {

        let element = $(document.createElement("a"));
        element.attr("href", item.url);
        element.attr("target", "_blank");
        element.addClass("instagram__image aspect-ratio aspect-ratio--1x1");

        let image = $(document.createElement("img"));
        image.attr("src", item.image);
        element.append(image);

        $(".instagram__matrix--shuffled").append(element);
    })
}