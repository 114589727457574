$(document).ready(function () {

    var eventsCtrl = new ScrollMagic.Controller();

    $('body').imagesLoaded(function () {

        var duration = $(".events__wrapper").height() - 637;
        var windowWidth = $(window).width();

        if (windowWidth >= 1260) {
            var triggerHook = 1 / $(window).height() * 96;
            new ScrollMagic.Scene({
                    triggerElement: ".events__heading-container",
                    triggerHook: triggerHook,
                    duration: duration,
                    reverse: true
                })
                .setPin(".events__heading-container", {
                    pushFollowers: false
                })
                .addTo(eventsCtrl);
        } else if (windowWidth >= 768) {
            var triggerHook = 1 / $(window).height() * 152;
            new ScrollMagic.Scene({
                    triggerElement: ".events__heading-container",
                    triggerHook: triggerHook,
                    duration: duration,
                    reverse: true
                })
                .setPin(".events__heading-container", {
                    pushFollowers: false
                })
                .addTo(eventsCtrl);

        }
    });


});