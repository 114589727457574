/* --------------------
    Event Handler
-------------------- */
$(document).on("afterChange", ".profile__slider", setSliderState);

$(document).ready(function () {
    initProfileSlider();

    $(".profile-slide-right").click(function (e) {
        e.preventDefault();

        console.log("testtest");
    });
});

/* --------------------
    Functions
-------------------- */
function initProfileSlider() {
    // Start Loop
    $(".profile__slider").each(function (key, item) {
        var sliderIdName = "profile__slider" + key;
        var sliderPrevName = "profile-slide-left" + key;
        var sliderNextName = "profile-slide-right" + key;

        this.id = sliderIdName;
        $(".profile-slide-left")[key].id = sliderPrevName;
        $(".profile-slide-right")[key].id = sliderNextName;

        var sliderId = "#" + sliderIdName;
        var sliderPrevId = "#" + sliderPrevName;
        var sliderNextId = "#" + sliderNextName;

        $(sliderId).slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: $(sliderPrevId),
            nextArrow: $(sliderNextId),
            variableWidth: true,
        });
    });
    //Ende loop
}

function setSliderState(event, slick, currentSlide) {
    console.log(currentSlide);
    console.log(slick.slideCount - 1);

    if (currentSlide == slick.slideCount - 1) {
        slick.$nextArrow.css("opacity", 0);
        slick.$nextArrow.css("pointer-events", "none");
    } else if (currentSlide == 0) {
        slick.$prevArrow.css("opacity", 0);
        slick.$prevArrow.css("pointer-events", "none");
    } else {
        slick.$nextArrow.css("opacity", 1);
        slick.$nextArrow.css("pointer-events", "all");
        slick.$prevArrow.css("opacity", 1);
        slick.$prevArrow.css("pointer-events", "all");
    }
}
