/* --------------------
    Event Handler
-------------------- */
$(document).on("click", ".accordion__item > span", openAccordion);

/* --------------------
    Functions
-------------------- */
function openAccordion() {
  if ($(this).parent().hasClass("is-active")) {
    $(this).parent().removeClass("is-active");
    $(this).parent().find(".rte").hide(".8");
  } else {
    $(this).parent().addClass("is-active");
    $(this).parent().find(".rte").show(".8");
  }
}
