$(document).ready(function () {
    initScrollMagic();
});

function initScrollMagic() {
    var controller = new ScrollMagic.Controller();

    var scene = new ScrollMagic.Scene({ triggerElement: "#instagram-trigger", triggerHook: .9 })
        .addTo(controller)
        .on("enter", function (e) {
            $(".instagram__matrix").removeClass("instagram__matrix--distributed");
            $(".instagram__matrix--shuffled").removeClass("instagram__matrix--shuffled--distributed");
        });

    /** Independent Minds Images */
    // new ScrollMagic.Scene({ triggerElement: ".independent-minds__animation-trigger", triggerHook: 1, reverse: true })
    //     .addTo(controller)
    //     .on("enter", (e) => {
    //         const timeline = gsap.timeline();
    //         timeline
    //             .fromTo(".independent-minds__animation-trigger img:first-of-type", {
    //                 opacity: 0,
    //                 yPercent: 50,
    //                 ease: "power4.in",
    //             }, {
    //                 duration: 1.2,
    //                 opacity: 1,
    //                 yPercent: 0
    //             }, "<")
    //             .fromTo(".independent-minds__animation-trigger img:nth-of-type(2)", {
    //                 opacity: 0,
    //                 yPercent: 100,
    //                 ease: "power4.in",
    //             }, {
    //                 duration: 1.2,
    //                 yPercent: 0,
    //                 opacity: 1
    //             }, "<.6")
    //             .fromTo(".independent-minds__animation-trigger img:nth-of-type(3)", {
    //                 opacity: 0,
    //                 yPercent: 100,
    //                 ease: "power4.in",
    //             }, {
    //                 duration: 1.2,
    //                 yPercent: 0,
    //                 opacity: 1
    //             }, "<.6")
    //             .fromTo(".independent-minds__animation-trigger img:nth-of-type(4)", {
    //                 opacity: 0,
    //                 yPercent: 50,
    //                 ease: "power4.in",
    //             }, {
    //                 duration: 1,
    //                 yPercent: 0,
    //                 opacity: 1
    //             }, "<.6")

    //     })


} 